import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import LoadingProvider from "./component/common/provider/LoadingProvider";
import Auth from "./component/home/Auth";
import Layout from "./component/app/layouts/Layout";
import RouteConstant from "./common/constants/routeConstant";
import { InjectAxiosInterceptors } from "./services/interceptors/InjectInterceptors";
import GlobalBlockProvider from "./component/common/provider/GlobalBlockProvider";
import { snackBarConfig } from "./component/common/customSnackBar/snackBarConfig";
import BaseGame from "./component/game/BaseGame";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store/rootReducer";
import { useDisableConsole } from "./component/hook/useDisableConsole";
import Demo from "./component/home/Demo";

const App = () => {
  useDisableConsole();
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <BrowserRouter>
        <GlobalBlockProvider>
          <LoadingProvider>
            <InjectAxiosInterceptors />
            <SnackbarProvider {...snackBarConfig}>
              <Routes>
                <Route element={<Layout />}>
                  <Route path="demo" element={<Demo />} />
                  <Route path={RouteConstant.Auth} element={<Auth />} />
                  {[RouteConstant.K3, RouteConstant.K5d, RouteConstant.Wingo].map((path) => (
                    <Route key={path} path={path} element={<BaseGame />} />
                  ))}
                </Route>
              </Routes>
            </SnackbarProvider>
          </LoadingProvider>
        </GlobalBlockProvider>
      </BrowserRouter>
      {/* </PersistGate> */}
    </Provider>
  );
};

export default App;
