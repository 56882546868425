import React from "react";
import ImageConstant from "../../common/constants/imageConstant";
import { useDispatch } from "react-redux";
import * as authEvent from "../../event/authEvent";
import GameConstant from "../../common/constants/gameConstant";
import { enqueueSnackbar } from "notistack";
import { useLoading } from "../common/provider/LoadingProvider";
import { useNavigate } from "react-router-dom";

const Demo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useLoading();

  const GamePosters = [
    { imgSrc: ImageConstant.posterWinGo, gameCode: GameConstant.GameCodes.WinGo },
    { imgSrc: ImageConstant.poster5d, gameCode: GameConstant.GameCodes.K5d },
    { imgSrc: ImageConstant.posterK3, gameCode: GameConstant.GameCodes.K3 },
  ];

  const demoLogin = async (game) => {
    isLoading("DemoAuthLoading", true);
    try {
      const { payload: redirectUrl } = await dispatch(authEvent.demoAuth({ game, language: GameConstant.Languages.English }));
      if (!redirectUrl) enqueueSnackbar({ message: "Something went wrong. Please try again later.", options: { variant: "error" } });
      navigate(redirectUrl);
    } catch (error) {
      console.log(error);
    } finally {
      isLoading("DemoAuthLoading", false);
    }
  };

  return (
    <div className="flex flex-col justify-between gap-8 p-10">
      <h1 className="text-center">Welcome to Lottery</h1>
      <div className="flex flex-col sm:flex-row gap-5">
        {GamePosters.map((game, idx) => {
          return (
            <div key={idx} className={`size-[10px] overflow-hidden relative group rounded-xl cursor-pointer`} onClick={() => demoLogin(game.gameCode)}>
              <img src={game.imgSrc} alt="wingo" className="w-full h-full transform transition-transform duration-300 group-hover:scale-110" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Demo;
