import { createAsyncThunk } from "@reduxjs/toolkit";

import * as Api from "../api/authApi";
import { setAccessToken, setCountry, setSignalRId, setUsername } from "../common/helpers/localStorage";

const authSuccessCallback = (response) => {
  setAccessToken(response.token);
  setUsername(response.username);
  setSignalRId(response.signalRId);
  // setCountry({
  //   iso2: response.countryIso2,
  //   currency: response.countryCurrency,
  // });
  setCountry({
    iso2: "IN",
    currency: "INR",
  });
};

export const authUser = createAsyncThunk("auth/authUser", async ({ sessionId, game }) => {
  try {
    const response = await Api.startSession(sessionId, game);
    if (response.isSuccess) authSuccessCallback(response);
    return response.redirectUrl;
  } catch (error) {
    console.log(error);
  }
});

export const demoAuth = createAsyncThunk("auth/demoAuth", async ({ game, language }) => {
  try {
    const response = await Api.demoAuth(game, language);
    if (response.isSuccess) authSuccessCallback(response);
    return response.redirectUrl;
  } catch (error) {
    console.log(error);
  }
});
